import { Component, OnInit } from '@angular/core';
// import * as moment from 'moment/ts3.1-typings/moment';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  bookingId: string = '';
  isRestaurantOrdered: boolean = false;

  constructor(
    private route: ActivatedRoute,

  ) {
    this.route.params.subscribe((res: any) => (this.bookingId = res.id));

  }

  ngOnInit() {
    console.log('****** this.bookingId ******', this.bookingId);

    if (this.bookingId.startsWith('REST')) {
      // Redirect to Restaurant component
      this.isRestaurantOrdered = true;
    } else {
      // Redirect to Tracking Component
      this.isRestaurantOrdered = false;
    }
    console.log('****** this.isRestaurantOrdered ******', this.isRestaurantOrdered);
  }


}

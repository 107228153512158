import { Injectable } from '@angular/core';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MQTTservicesService {

  constructor(private _mqttService: MqttService) { }

  topic(deviceId: string): Observable<IMqttMessage> {
    return this._mqttService.observe(deviceId);
  }
}

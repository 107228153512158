import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './Page/home-page/home-page.component';
import { HttpClientModule } from '@angular/common/http';
import { IMqttServiceOptions, MqttModule, MqttService } from 'ngx-mqtt';
import { environment } from 'src/environments/environment';
import { MQTTservicesService } from './Services/mqttservices.service';
import { TrackComponent } from './Page/track/track.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RestaurantComponent } from './Page/restaurant/restaurant.component';


const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: environment.mqtt.hostName,
  path: environment.mqtt.path,
  protocol: environment.mqtt.protocol === "wss" ? "wss" : "ws",
  clean: environment.mqtt.clean,
  connectTimeout: environment.mqtt.connectTimeout,
  clientId: environment.mqtt.clientId,
  username: environment.mqtt.userName,
  password: environment.mqtt.password,
  reconnectPeriod: environment.mqtt.reconnectPeriod,
  keepalive: environment.mqtt.keepalive
};


// const REST_MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
//   hostname: environment.restMqtt.hostName,
//   path: environment.restMqtt.path,
//   protocol: environment.restMqtt.protocol === "wss" ? "wss" : "ws",
//   clean: environment.restMqtt.clean,
//   connectTimeout: environment.restMqtt.connectTimeout,
//   clientId: environment.restMqtt.clientId,
//   username: environment.restMqtt.userName,
//   password: environment.restMqtt.password,
//   reconnectPeriod: environment.restMqtt.reconnectPeriod,
//   keepalive: environment.restMqtt.keepalive
// };


@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    TrackComponent,
    RestaurantComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    // MqttModule.forRoot(REST_MQTT_SERVICE_OPTIONS),
    AppRoutingModule
  ],
  providers: [MQTTservicesService],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './Page/home-page/home-page.component';
import { RestaurantComponent } from './Page/restaurant/restaurant.component';

const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: ':id', component: HomePageComponent },
  { path: 'home/:id', component: HomePageComponent },
  { path: 'rest/:id', component: RestaurantComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }

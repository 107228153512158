<div [ngStyle]="{ display: !isLoading ? '' : 'none' }" class="container">
    <div class="row mt-3">
        <div class="col-md-8 col-12">
            <div class="shimmerBG media"></div>
        </div>
        <div class="col-md-4 col-12">
            <div class="">
                <div class="row mt-3">
                    <div class="col-md-3 col-sm-3 col-3">
                        <div class="shimmerBG title-line end"></div>
                    </div>
                    <div class="col-md-9 col-9">
                        <div class="shimmerBG content-line"></div>
                        <div class="shimmerBG content-line"></div>
                        <div class="shimmerBG content-line"></div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-3 col-sm-3 col-3">
                        <div class="shimmerBG title-line end"></div>
                    </div>
                    <div class="col-md-9 col-9">
                        <div class="shimmerBG content-line"></div>
                        <div class="shimmerBG content-line"></div>
                        <div class="shimmerBG content-line"></div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-3 col-sm-3 col-3">
                        <div class="shimmerBG title-line end"></div>
                    </div>
                    <div class="col-md-9 col-9">
                        <div class="shimmerBG content-line"></div>
                        <div class="shimmerBG content-line"></div>
                        <div class="shimmerBG content-line"></div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-3 col-sm-3 col-3">
                        <div class="shimmerBG title-line end"></div>
                    </div>
                    <div class="col-md-9 col-9">
                        <div class="shimmerBG content-line"></div>
                        <div class="shimmerBG content-line"></div>
                        <div class="shimmerBG content-line"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-6 col-12">
            <div class="shimmerBG title-line"></div>
        </div>
        <div class="col-md-6 col-12">
            <div class="shimmerBG title-line"></div>
        </div>
    </div>
</div>

<div [ngStyle]="{ display: isBlur ? '' : 'none' }" class="fix_content_wrap">
    <div class="fixed_content text-center">
        <h1>Uh-oh!</h1>
        <label>Sorry! This should not have happened. Please retry.</label>
        <br />
        <button class="btn btn-primary pl-4 pr-4" (click)="refresh()" [style.background]="secondColor">
            Retry
        </button>
    </div>
</div>


<div class="googleMapStyle" [ngStyle]="{ display: !isLoading ? 'none' : '' }"
    [ngClass]="{ bookingHide: bottomSheet === true }"
    [ngStyle]="isBlur ? {filter : 'blur(5px)','pointer-events' :'none'} : {}">
    <div [hidden]="isCompletedBooking" class="map" id="map" class="match-parent"></div>
</div>

<!-- <div class="sticky" [ngStyle]="{ display: bottomSheet ? 'none' : '' }">
    <hr class="lineIcon" />
</div> -->

<div class="sticky" [ngStyle]="{ display: bottomSheet ? 'none' : '', 'margin-top': isCompletedBooking ? '' : '65vh' }">
    <hr class="lineIcon" [ngStyle]="{ display: !bottomSheet ? 'none' : ''}" />
</div>


<!-- <div class="travel_duration">
    <div *ngIf="statusFlag == 3">
        Driver reaching in {{ distanceMinute }}
    </div>
    <div *ngIf="statusFlag == 8">
        Reaching destination in {{ distanceMinute }}
    </div>
</div> -->

<!-- <div class="theme_switch">
    <label class="switch">
        <input type="checkbox" (change)="themeChange($event)">
        <span class="slider"></span>
    </label>
</div> -->


<div class="bookingAndDriverDetailMainBox" [ngStyle]="{ display: bottomSheet ? 'none' : '' }">
    <div class="BookingDetailsMainDivShow" *ngIf="isLoading" id="BookingDetailsMainDivHideShow">
        <div class="BookingActivity" [style.background]="firstColor">
            <div class="row">
                <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xs-1">
                    <i class="fa fa-circle" style="font-size: 0.7em" [style.color]="secondColor" aria-hidden="true"></i>
                    <div class="devider" [style.border-color]="secondColor"></div>
                </div>
                <div class="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 col-xs-11">
                    <p class="ec giveEllipsis" [style.color]="secondColor">
                        {{ pickupLatLng?.address }}
                    </p>
                </div>
                <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xs-1">
                    <i class="fa fa-square" style="font-size: 0.7em" [style.color]="secondColor" aria-hidden="true"></i>
                </div>
                <div class="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 col-xs-11">
                    <p class="ec giveEllipsis" [style.color]="secondColor">
                        {{ dropLatLng?.address }}
                    </p>
                </div>
            </div>
        </div>
        <div class="driverDetails">
            <div class="row">
                <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <p class="fnt">
                        CRN : <span class="fnt-weight"> #{{ bookingId }}</span>
                    </p>
                    <p class="fnt" *ngIf="bookingDetails?.isDestinationLater != 1">
                        Time :
                        <span class="fnt-weight">
                            {{ bookingDetails?.totalApproxTime }} Min
                        </span>
                    </p>
                    <div class="profile-img">
                        <img width="70" height="70" style="border-radius: 50%" src="{{ driverImage }}" alt="" />
                        <div style="clear: both"></div>
                        <p class="fnt truncate" style="padding-left: 0">
                            {{
                            driverDetails?.driverName ? driverDetails?.driverName : "N/A"
                            }}
                        </p>
                        <a class="fnt" style="margin-left: -16px" href="tel:{{
                  driverDetails?.mobile ? driverDetails?.mobile : 'N/A'
                }}"><i class="fa fa-phone"></i>
                            <span>&nbsp;&nbsp;{{
                                driverDetails?.mobile ? driverDetails?.mobile : "N/A"
                                }}</span>
                        </a>
                    </div>
                </div>
                <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <p class="fnt p-left" *ngIf="bookingDetails?.isDestinationLater != 1">
                        Amount :
                        <span class="fnt-weight">
                            {{ settingInfo?.currency }}
                            {{ bookingDetails?.estimatedFinalAmount.toFixed(3) }}
                            <!-- {{ bookingDetails?.estimatedAmount.toFixed(3) }} -->
                        </span>
                    </p>
                    <p class="fnt p-left" *ngIf="bookingDetails?.isDestinationLater != 1">
                        Distance :
                        <span class="fnt-weight">
                            {{ bookingDetails?.totalApproxKm.toFixed(3) }}
                            {{
                            bookingDetails?.distanceVariant != undefined &&
                            bookingDetails?.distanceVariant == 1
                            ? "Miles"
                            : "Km"
                            }}</span>
                    </p>
                    <div class="car-type text-center" style="margin-top: 10px;">
                        <!-- <img *ngIf="!carIcon" width="100" height="70" src="../../assets/img/cars.png" alt="" /> -->
                        <img width="100" height="70" src="{{ carIcon }}" alt="" />
                        <p class="fnt">
                            {{
                            carDetails?.carNumberAndType
                            ? carDetails?.carNumberAndType
                            : "---"
                            }}
                        </p>
                        <p class="fnt">
                            {{
                            carDetails?.carModalAndColor
                            ? carDetails?.carModalAndColor
                            : "---"
                            }}
                        </p>
                        <div style="clear: both"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="wrapper">
            <div class="float-right stampImg">
                <img class="img-fluid" width="100" height="100" [src]="stamp" alt="" />
            </div>
            <ul class="StepProgress">
                <li class="StepProgress-item is-done">
                    <strong>Created</strong>{{ moment(bookingDetails?.bookCreateDateTime).format('DD MMM HH:mm:ss') }}
                </li>
                <li class="StepProgress-item is-done" *ngIf="bookingDetails?.acceptedTime">
                    <strong>Driver Accepted</strong> {{ moment(bookingDetails?.acceptedTime).format('DD MMM HH:mm:ss')
                    }}
                </li>
                <li class="StepProgress-item is-done" *ngIf="bookingDetails?.arrivedTime">
                    <strong>Driver Arrived</strong>
                    {{
                    bookingDetails?.arrivedTime
                    ? moment(bookingDetails?.arrivedTime).format("DD MMM HH:mm:ss")
                    : "Driver Will Arrived You Soon"
                    }}
                </li>
                <li class="StepProgress-item is-done" *ngIf="bookingDetails?.beginTime">
                    <strong>On Trip</strong>
                    <!-- current -->
                    {{
                    bookingDetails?.beginTime
                    ? moment(bookingDetails?.beginTime).format("DD MMM HH:mm:ss")
                    : "Your trip will begin soon"
                    }}
                </li>
                <li class="StepProgress-item" [ngClass]="bookingDetails?.dropTime ? 'is-done' : ''">
                    <strong>Est. Complete</strong>
                    {{ bookingDetails?.dropTime ? "" : "Estimated Time : " }}
                    {{
                    bookingDetails?.dropTime
                    ? moment(bookingDetails?.dropTime).format('DD MMM HH:mm:ss')
                    : moment(displayEstimationComplete).format('DD MMM HH:mm:ss')
                    }}
                </li>
                <li class="StepProgress-item is-done" *ngIf="bookingDetails?.completeTime">
                    <strong
                        *ngIf="bookingDetails?.status == 4 || bookingDetails?.status == 5 || bookingDetails?.status == 11">Cancelled</strong>
                    <strong
                        *ngIf="bookingDetails?.status != 4 && bookingDetails?.status != 5 && bookingDetails?.status != 11">Complete</strong>
                    {{ bookingDetails?.completeTime ? "" : "Estimated Time : " }}
                    {{
                    bookingDetails?.completeTime
                    ? moment(bookingDetails?.completeTime).format('DD MMM HH:mm:ss')
                    : moment(displayCompleteTime).format('DD MMM HH:mm:ss')
                    }}
                </li>
            </ul>
        </div>
    </div>

    <div *ngIf="!isLoading">
        <div class="card">
            <div class="shimmerBG media"></div>
            <div class="p-32">
                <div class="shimmerBG title-line"></div>
                <div class="shimmerBG title-line end"></div>
                <div class="shimmerBG content-line m-t-24"></div>
                <div class="shimmerBG content-line"></div>
                <div class="shimmerBG content-line"></div>
                <div class="shimmerBG content-line"></div>
                <div class="shimmerBG content-line end"></div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="bookingDetails?.otp" [style.background]="secondColor" class="otpStyle">
    <i class="fa fa-key" aria-hidden="true"></i> OTP :
    <span>
        {{ bookingDetails?.otp }}
    </span>
</div>
import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
// import * as moment from 'moment/ts3.1-typings/moment';
import { ActivatedRoute } from '@angular/router';
import * as polyline from '@mapbox/polyline';
import * as turf from '@turf/turf';
import * as moment from "moment";
import * as moment1 from 'moment-timezone';
import { IMqttMessage } from 'ngx-mqtt';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/Services/api.service';
import { MQTTservicesService } from 'src/app/Services/mqttservices.service';

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.scss']
})
export class RestaurantComponent implements OnInit {

  map: any = mapboxgl.Map;
  private subscriptionMQTT: Subscription = new Subscription;
  private orderSubscriptionMqtt: Subscription = new Subscription;
  private carMarker!: mapboxgl.Marker;
  lat: number = 29.3425916;
  lng: number = 48.0702437;
  bookingId: string = '';
  isFirstTime: boolean = true;
  isBlur: boolean = false;
  bottomSheet: boolean = false;
  settingInfo: any = null;
  mapCustomStyle: any[] = [];
  companyDetails: any = null;
  driverDetails: any = null;
  bookingDetails: any = null;
  statusFlag: number = 0;
  carDetailsObj: any = null;
  driverId: string = '';
  driverImage: string = '../../assets/img/no-img.png';
  stamp: string = '';
  carIcon: string = '';
  carDetails!: { carNumberAndType: string; carModalAndColor: string, carNumberPlate: string };
  iconUrl: string = '../../assets/img/car/0.png';
  companyIcon: string = '';
  userDetails: any = null;
  renderOptions: any = {
    suppressMarkers: true,
    polylineOptions: { strokeColor: 'black', strokeWeight: 3 },
  };
  firstColor: string = '#276EF1';
  secondColor: string = '';
  thirdColor: string = '#ffffff';
  pickupLatLng!: { lat: 0; lng: 0; address: 'Pickup address' };
  dropLatLng!: { lat: 0; lng: 0; address: 'Drop address' };
  displayEstimationComplete: string = 'Soon';
  displayCompleteTime: string = 'Soon';
  modeLocations: any[] = [];
  distanceMinute: any = 0;
  driverCurrentLatLong: any = { lat: 0, lng: 0 };
  moment: any = moment;
  mapBoxKey: any = "";
  constructor(
    public api: ApiService,
    private route: ActivatedRoute,
    private MqttServices: MQTTservicesService, // private mapsAPILoader: MapsAPILoader
    private zone: NgZone,
    private cdr: ChangeDetectorRef,
    private toaster: ToastrService

  ) {
    this.route.params.subscribe((res: any) => (this.bookingId = res.id));
  }

  ngOnInit() {
    if (!this.bookingId) {
      this.isFirstTime = false;
      this.isBlur = true;
    } else {
      console.log(this.bookingId, 'this.bookingId');
      this.mapBoxKey = this.getValidGoogleKey(environment.mapbox.accessToken);
      this.mapInt();
      this.startProcess();
    }

  }

  getValidGoogleKey(key: any) {
    if (!key) return { status: false, key: null };
    if (key.length == 1) return { status: true, key };

    const currentDate = new Date().getDate();
    let index = currentDate % key.length;
    index = Math.round(index);
    return { status: true, key: key[index] };
  }

  calculateBookingETA() {
    setTimeout(() => {
      console.log('*****>>> startProcess <<<******');
      if (
        this.bookingDetails['isDestinationLater'] == 1 ||
        this.bookingDetails['bookingFlag'] == 1
      ) {
        if (
          this.bookingDetails['isDestinationLater'] == '' &&
          this.bookingDetails['bookingFlag'] == 1
        ) {

          if (this.statusFlag == 2) {
            this.distanceMinute = this.getEstimatedTripTime(
              this.pickupLatLng.lat,
              this.pickupLatLng.lng,
              this.driverCurrentLatLong.lat,
              this.driverCurrentLatLong.lng
            );
          }

          if (this.statusFlag == 3) {
            this.distanceMinute = this.getEstimatedTripTime(
              this.pickupLatLng.lat,
              this.pickupLatLng.lng,
              this.driverCurrentLatLong.lat,
              this.driverCurrentLatLong.lng
            );
            console.log('****** accept this.distanceMinute  ******', this.distanceMinute);
            this.cdr.detectChanges();
            this.showInfoWindow([this.dropLatLng.lng, this.dropLatLng.lat], this.distanceMinute);
          }

        }
      } else {
        if (this.statusFlag == 80 || this.statusFlag == 8) {

          this.distanceMinute = this.getNewEstimatedTripTimeAfterPOB(
            this.driverCurrentLatLong.lat,
            this.driverCurrentLatLong.lng,
            false
          );
          console.log('******  getNewEstimatedTripTimeAfterPOB this.distanceMinute ******', this.distanceMinute);
          // this.showInfoWindow([this.dropLatLng.lng, this.dropLatLng.lat], this.distanceMinute)
        } else {
          if (this.statusFlag == 7) {
            this.infoWindow.remove();

            return;
          }

          this.distanceMinute = this.getEstimatedTripTime(
            this.pickupLatLng.lat,
            this.pickupLatLng.lng,
            this.driverCurrentLatLong.lat,
            this.driverCurrentLatLong.lng
          );
          console.log('****** getEstimatedTripTime distanceMinute ******', this.distanceMinute);
          this.cdr.detectChanges();
          if (
            this.driverCurrentLatLong.lat &&
            this.driverCurrentLatLong.lng
          ) {
            this.showInfoWindow([this.pickupLatLng.lng, this.pickupLatLng.lat], this.distanceMinute);
          }

        }
      }
      this.moveCar();
      this.animateCar();
    }, 1100);
  }

  mapInt() {
    this.map = new mapboxgl.Map({
      accessToken: this.mapBoxKey.key,
      // style: 'mapbox://styles/mapbox/streets-v12',
      style: 'mapbox://styles/mapbox/light-v11',
      // style: 'mapbox://styles/mapbox/dark-v11',
      // style: 'mapbox://styles/mapbox/light-v11'
      container: 'restMap',
      pitch: 5,
      zoom: 18,
      maxZoom: 24,
      center: [this.lng, this.lat],
      fitBoundsOptions: { padding: 80 }
    });
  }

  isLoading: boolean = false;
  startProcess() {
    this.isLoading = false;
    return new Promise<any>((resolve) => {
      this.api.getRestaurantBooking(this.bookingId).subscribe((data: any) => {
        this.isLoading = true;
        // this.polylineObject = data.locationJson;
        console.log('******data  startProcess******', data);
        let resultData = data;
        if (resultData.status) {
          // this.lat = resultData.companyDetails.lat;
          // this.lng = resultData.companyDetails.lng;
          console.log('****** this.lng ******', this.lng);
          console.log('******  this.lat ******', this.lat);
          this.isCompletedBooking = resultData?.isSql;
          if (!this.isCompletedBooking) {
            this.calculateBookingETA();
          }
          this.bookingDetails = resultData['bookingDetails'];
          console.log('******  resultData?.isSql ******', resultData?.isSql);
          console.log('****** call this.isCompletedBooking ******', this.isCompletedBooking);
          this.isBlur = false;
          this.bottomSheet = false;
          this.settingInfo = resultData['settingInfo'];
          this.companyDetails = resultData['companyDetails'];
          this.driverDetails = resultData['driverDetails'];

          this.pickupLatLng = {
            lat: this.bookingDetails['locationJson'][0]['lat'],
            lng: this.bookingDetails['locationJson'][0]['long'],
            address: this.bookingDetails['locationJson'][0]['address']
              ? this.bookingDetails['locationJson'][0]['address']
              : 'Pickup address',
          };
          // this.setMultipleLocation(this.bookingDetails['locationJson'])
          console.log('****** this.pickupLatLng ******', this.pickupLatLng);
          this.orderMqttSub(this.bookingDetails.bookingId, this.bookingDetails.companyId);

          this.driverId = this.driverDetails['driverId'];


          console.log('****** this.bookingDetails[bookingFlag] ******', this.bookingDetails['bookingFlag']);

          switch (this.bookingDetails['bookingFlag']) {
            case 0:
              this.stamp = '../../assets/img/dailyRide.png';
              break;
            case 1:
              this.stamp = '../../assets/img/rental.png';
              break;
            case 2:
              this.stamp = '../../assets/img/outstation.png';
              break;
            default:
              this.stamp = '../../assets/img/dailyRide.png';
              break;
          }
          if (this.bookingDetails['isDestinationLater'] == 1) {
            this.stamp = '../../assets/img/laterDestination.png';
          }

          console.log('****** this.stamp ******', this.stamp);
          this.statusFlag = this.bookingDetails['status'];
          this.carDetailsObj = resultData['carDetails'];
          this.userDetails = resultData['userDetails'];
          this.driverImage = this.driverDetails['driverImage']
            ? this.api['imageUrl'] +
            this.api['driverFolderName'] +
            this.driverDetails['driverImage']
            : '../../assets/img/no-img.png';

          this.carIcon = this.carDetailsObj['carIcon'] != ""
            ? this.api['imageUrl'] +
            this.api['carFolderName'] +
            this.carDetailsObj['carSideIcon']
            : '../../assets/img/cars.png';

          console.log('******  this.carIcon ******', this.carIcon);

          const driverLatLong = this.driverDetails['currentLocation']
            ? this.driverDetails['currentLocation']
            : [];
          this.driverCurrentLatLong.lat = driverLatLong[0];
          this.driverCurrentLatLong.lng = driverLatLong[1];

          this.getCarTopIcons(this.carDetailsObj);
          if (
            this.carDetailsObj['licensePlate'] != '' &&
            this.carDetailsObj['carTypeName'] &&
            this.carDetailsObj['carModelName'] != '' &&
            this.carDetailsObj['carBrandName'] != ''
          ) {
            this.carDetails = {
              carNumberAndType:
                this.carDetailsObj['carTypeName'],
              carModalAndColor:
                this.carDetailsObj['carModelName'] +
                ' (' +
                this.carDetailsObj['carBrandName'] +
                ')',
              carNumberPlate:
                this.carDetailsObj['licensePlate']
            };
          }
          this.companyIcon =
            this.api['imageUrl'] +
            this.api['companyFolderName'] +
            this.settingInfo['logo'];
          /* mqtt subscribe on booking ID */
          if (!this.isCompletedBooking) {
            const bookingID = this.bookingDetails['bookingId'];
            const mqttTopic = `track_booking_${bookingID}`;
            console.log('****** mqttTopic ******', mqttTopic);
            this.mqtt(mqttTopic);
          }


          var color = this.companyDetails['colors'];
          var colorArray = color.split(',');
          console.log('****** colorArray ******', colorArray);
          this.firstColor = colorArray[0] ? colorArray[0] : '#276EF1';
          this.secondColor = colorArray[1];
          this.thirdColor = colorArray[2] ? colorArray[2] : '#ffffff';
          this.renderOptions['polylineOptions']['strokeColor'] = colorArray[14]
            ? colorArray[14]
            : 'black';

          if (
            this.bookingDetails['isDestinationLater'] == 1 ||
            this.bookingDetails['bookingFlag'] == 1
          ) {
          } else {
            this.dropLatLng = {
              lat: this.bookingDetails['locationJson'][
                this.bookingDetails['locationJson'].length - 1
              ]['lat'],
              lng: this.bookingDetails['locationJson'][
                this.bookingDetails['locationJson'].length - 1
              ]['long'],
              address: this.bookingDetails['locationJson'][
                this.bookingDetails['locationJson'].length - 1
              ]['address']
                ? this.bookingDetails['locationJson'][
                this.bookingDetails['locationJson'].length - 1
                ]['address']
                : 'Drop address',
            };
          }
          this.companySettings();
          if (!this.isCompletedBooking) {
            this.checkDayOrNight();
            this.drawPolyline(resultData.bookingDetails.locationJson);
            this.mqtt(this.driverId);
            this.moveCar();
          }
          this.currentIndex = 0;
          resolve(true);
        } else {
          console.log('*****>>> else false status <<<******');
          this.isBlur = true;
          this.bottomSheet = true;
        }
        this.isFirstTime = false;
      });
    });
  }


  companySettings() {
    let favIcon = document.getElementById('favIcon') as HTMLLinkElement;
    favIcon.setAttribute(
      'href',
      this.api['imageUrl'] +
      this.api['companyFolderName'] +
      this.settingInfo['favicon']
    );
    let pageTitle = document.getElementById(
      'pageTitle'
    ) as HTMLTitleElement;
    pageTitle.innerHTML += ' |' + ' ' + this.settingInfo['pageTitle'];
    this.displayEstimationComplete = this.ConvertDateFormate(
      moment(this.bookingDetails['pickupTime']).add(
        this.bookingDetails['totalApproxTime'],
        'minutes'
      )
    );
  }

  isCompletedBooking: boolean = false;
  mqtt(id: string) {
    console.log('****** mqtt id ******', id);
    this.subscriptionMQTT = this.MqttServices.topic(id).subscribe((data: IMqttMessage) => {
      try {
        let mqttResponse: any = data.payload.toString();
        if (mqttResponse) {
          mqttResponse = JSON.parse(mqttResponse);
          console.log('****** mqttResponse ******', mqttResponse);
          if (mqttResponse.isTracking == true) {
            console.log('****** mqttResponse.locationData.length ******', mqttResponse.locationData.length);
            if (mqttResponse.locationData.length > 0) {
              // if (this.modeLocations.length > 0) {
              //   mqttResponse.locationData.forEach((element: any) => {
              //     console.log('****** element ******', element);
              //     this.modeLocations.push(element);
              //   });
              // } else {
              //   this.modeLocations = mqttResponse.locationData;
              // }
              this.modeLocations = mqttResponse.locationData;
              console.log('****** this.modeLocations ******', this.modeLocations);

              this.driverCurrentLatLong.lat = mqttResponse.locationData[0].latitude;
              this.driverCurrentLatLong.lng = mqttResponse.locationData[1].longitude;
              this.distanceMinute = this.getNewEstimatedTripTimeAfterPOB(
                this.driverCurrentLatLong.lat,
                this.driverCurrentLatLong.lng,
                false
              );
              this.currentIndex = 0;
              this.animateCar();
              this.adjustMapBounds()
              console.log('****** this.statusFlag == 80  this.distanceMinute ******', this.distanceMinute);
              // this.showInfoWindow([this.driverCurrentLatLong.lng, this.driverCurrentLatLong.lat], `Pickup to Driver: ${this.distanceMinute}`);
              // this.showInfoWindow([this.dropLatLng.lng, this.dropLatLng.lat], `Driver to Drop : ${this.distanceMinute}`);
              // requestAnimationFrame(this.animateCar)
            }

          }
          if (mqttResponse['acceptedTime']) {
            this.bookingDetails.acceptedTime = mqttResponse['acceptedTime'];
          }
          if (mqttResponse['arrivedTime']) {
            this.bookingDetails.arrivedTime = mqttResponse['arrivedTime'];
          }
          if (mqttResponse['beginTime']) {
            this.bookingDetails.beginTime = mqttResponse['beginTime'];
          }
          if (mqttResponse['dropTime']) {
            this.bookingDetails.dropTime = mqttResponse['dropTime'];
          }
          if (mqttResponse['completeTime']) {
            this.bookingDetails.completeTime = mqttResponse['completeTime'];
          }
          if (mqttResponse['statusFlag']) {
            this.statusFlag = mqttResponse['statusFlag'];

            if (this.statusFlag == 3) {
              this.refresh(); //! vishesh need to discuss
              // this.carMarker.remove();

              // this.startProcess();
              this.getDriverData(mqttResponse);
              this.currentIndex = 0;
              this.moveCar();
              this.showInfoWindow([this.dropLatLng.lng, this.dropLatLng.lat], this.distanceMinute);

              // this.animateCar()
              // requestAnimationFrame(this.animateCar)
              // this.mapReady();
              this.driverCurrentLatLong.lat =
                mqttResponse.driverDetails.location[0];
              this.driverCurrentLatLong.lng =
                mqttResponse.driverDetails.location[1];
              this.getCarTopIcons(mqttResponse.driverDetails);
              console.log('*****>>> driver accept <<<******');
            }

            if (
              this.statusFlag == 12 ||
              this.statusFlag == 19 ||
              this.statusFlag == 14 ||
              this.statusFlag == 9 ||
              this.statusFlag == 15

            ) {
              // this.bottomSheet = true;
              // this.isBlur = true;
              this.refresh();
              this.subscriptionMQTT.unsubscribe();
            }
            // this.infoWin.close();
            if (this.statusFlag == 27) {
              this.bookingDetails.otp = mqttResponse.otp;
              console.log(
                '****** this.bookingDetails.otp  ******',
                this.bookingDetails.otp
              );
            }
            if (
              this.bookingDetails['isDestinationLater'] != 1 ||
              this.bookingDetails['bookingFlag'] != 1
            ) {
              if (this.statusFlag == 80) {
                // this.infoWin.open(this.map, this.markerP2);

                this.showInfoWindow([this.dropLatLng.lng, this.dropLatLng.lat], this.distanceMinute);
              } else {
                if (this.statusFlag == 81 || this.statusFlag == 8) {
                  return;
                }
                if (this.statusFlag == 7) {
                  // this.infoWin.close();
                  this.driverCurrentLatLong.lat =
                    mqttResponse.driverDetails.location[0];
                  this.driverCurrentLatLong.lng =
                    mqttResponse.driverDetails.location[1];
                  this.infoWindow?.remove();
                  // this.isInfoWindowHide = false;
                  // this.showInfoWindow([this.pickupLatLng.lng, this.pickupLatLng.lat], this.distanceMinute);

                  return;
                }
                // Edit booking
                if (this.statusFlag == 13) {
                  this.refresh();
                }
                // this.infoWin.open(this.map, this.markerP1);
              }
            }
          }
        }
      } catch (error) {
        console.log('****** MqttServices error ******', error);
      }
    });
  }


  mqtt1(id: string) {
    console.log('****** mqtt id ******', id);
    this.subscriptionMQTT = this.MqttServices.topic(id).subscribe((data: IMqttMessage) => {
      try {
        const mqttResponse: any = JSON.parse(data.payload.toString());
        console.log('****** mqttResponse ******', mqttResponse);

        if (mqttResponse.isTracking && mqttResponse.locationData && mqttResponse.locationData.length > 0) {
          this.handleLocationData(mqttResponse.locationData);
          this.distanceMinute = this.getNewEstimatedTripTimeAfterPOB(this.driverCurrentLatLong.lat, this.driverCurrentLatLong.lng, false);
          this.animateCar();
          this.adjustMapBounds();
          this.showInfoWindow([this.dropLatLng.lng, this.dropLatLng.lat], this.distanceMinute);
        }

        this.updateBookingDetails(mqttResponse);

        if (mqttResponse.statusFlag) {
          this.handleStatusFlag(mqttResponse.statusFlag, mqttResponse);
        }
      } catch (error) {
        console.log('****** MqttServices error ******', error);
      }
    });
  }

  handleLocationData(locationData: any[]) {
    this.modeLocations = locationData;
    console.log('****** this.modeLocations ******', this.modeLocations);
    this.driverCurrentLatLong.lat = locationData[0].latitude;
    this.driverCurrentLatLong.lng = locationData[1].longitude;
  }

  updateBookingDetails(mqttResponse: any) {
    const fieldsToUpdate = ['acceptedTime', 'arrivedTime', 'beginTime', 'dropTime', 'completeTime'];
    fieldsToUpdate.forEach(field => {
      if (mqttResponse[field]) {
        this.bookingDetails[field] = mqttResponse[field];
      }
    });
  }

  handleStatusFlag(statusFlag: number, mqttResponse: any) {
    switch (statusFlag) {
      case 3:
        this.refresh();
        this.getDriverData(mqttResponse);
        this.currentIndex = 0;
        this.moveCar();
        this.driverCurrentLatLong.lat = mqttResponse.driverDetails.location[0];
        this.driverCurrentLatLong.lng = mqttResponse.driverDetails.location[1];
        this.getCarTopIcons(mqttResponse.driverDetails);
        console.log('*****>>> driver accept <<<******');
        break;
      case 12:
      case 19:
      case 14:
      case 9:
      case 15:
        this.refresh();
        this.infoWindow?.remove()
        this.subscriptionMQTT.unsubscribe();
        break;
      case 27:
        this.bookingDetails.otp = mqttResponse.otp;
        console.log('****** this.bookingDetails.otp  ******', this.bookingDetails.otp);
        break;
      default:
        if (this.bookingDetails.isDestinationLater !== 1 || this.bookingDetails.bookingFlag !== 1) {
          if (statusFlag == 80) {
            this.showInfoWindow([this.driverCurrentLatLong.lng, this.driverCurrentLatLong.lat], this.distanceMinute);
          } else if (statusFlag == 7) {
            this.driverCurrentLatLong.lat = mqttResponse.driverDetails.location[0];
            this.driverCurrentLatLong.lng = mqttResponse.driverDetails.location[1];
            // this.showInfoWindow([this.pickupLatLng.lng, this.pickupLatLng.lat], this.distanceMinute);
            this.infoWindow?.remove();
          } else if (statusFlag == 13) {
            this.refresh();
          }
        }
        break;
    }
  }

  currentIndex: number = 0;
  carImage: any = ""
  getCarTopIcons(carDetailsObj: any) {
    console.log('*****>>> load car top icon <<<******');
    this.iconUrl = carDetailsObj['carIcon']
      ? this.api['imageUrl'] +
      this.api['carFolderName'] +
      carDetailsObj['carIcon']
      : '../../assets/img/cars.png';

    console.log('******  this.iconUrl  ******', this.iconUrl);
    // Dynamically create an image element for the car marker
    this.carImage = document.createElement('img');
    this.carImage.src = this.iconUrl; // Replace with the path to your car image
    this.carImage.className = 'car-icon';
    this.carImage.style.height = '55px';

    console.log('****** this.carImage ******', this.carImage);

    // Create the car marker with the dynamic image
    console.log('****** this.driverCurrentLatLong.lng ******', this.driverCurrentLatLong.lng);
    console.log('****** this.driverCurrentLatLong.lat ******', this.driverCurrentLatLong.lat);
    this.carMarker = new mapboxgl.Marker(this.carImage)
      .setLngLat([this.driverCurrentLatLong.lng, this.driverCurrentLatLong.lat])
      .addTo(this.map);
    console.log('******  this.carMarker ******', this.carMarker);
  }

  getDriverData(driver: any) {
    this.driverDetails = driver['driverDetails'];
    console.log(
      '****** getDriverData this.driverDetails ******',
      this.driverDetails
    );
    /* mqtt subscribe driverId */
    this.driverId = this.driverDetails['driverId'];
    this.mqtt(this.driverId);
    this.driverImage = this.driverDetails['driverImage']
      ? this.api['imageUrl'] +
      this.api['driverFolderName'] +
      this.driverDetails['driverImage']
      : '../../assets/img/no-img.png';

    console.log('****** this.driverImage ******', this.driverImage);

    this.carIcon = this.driverDetails['carSideIcon']
      ? this.api['imageUrl'] +
      this.api['carFolderName'] +
      this.driverDetails['carSideIcon']
      : '../../assets/img/cars.png';

    this.carDetails = {
      carNumberAndType:
        this.carDetailsObj['carTypeName'],
      carModalAndColor:
        this.carDetailsObj['carModelName'] +
        ' (' +
        this.carDetailsObj['carBrandName'] +
        ')',
      carNumberPlate:
        this.carDetailsObj['licensePlate']
    };

    console.log('****** this.carIcon  ******', this.carIcon);
  }

  ConvertDateFormate(time_select: moment.MomentInput) {
    let dateFormat;
    if (this.settingInfo['dateFormate'] == '1') {
      dateFormat = moment(time_select).format('DD/MM/YYYY hh:mm a');
    } else if (this.settingInfo['dateFormate'] == '2') {
      dateFormat = moment(time_select).format('DD/MM/YYYY HH:mm:ss');
    } else if (this.settingInfo['dateFormate'] == '3') {
      dateFormat = moment(time_select).format('DD/YYYY/MM hh:mm a');
    } else if (this.settingInfo['dateFormate'] == '4') {
      dateFormat = moment(time_select).format('DD/YYYY/MM HH:mm:ss');
    } else if (this.settingInfo['dateFormate'] == '5') {
      dateFormat = moment(time_select).format('MM/DD/YYYY hh:mm a');
    } else if (this.settingInfo['dateFormate'] == '6') {
      dateFormat = moment(time_select).format('MM/DD/YYYY HH:mm:ss');
    } else if (this.settingInfo['dateFormate'] == '7') {
      dateFormat = moment(time_select).format('MM/YYYY/DD hh:mm a');
    } else if (this.settingInfo['dateFormate'] == '8') {
      dateFormat = moment(time_select).format('MM/YYYY/DD HH:mm:ss');
    } else if (this.settingInfo['dateFormate'] == '9') {
      dateFormat = moment(time_select).format('YYYY/DD/MM hh:mm a');
    } else if (this.settingInfo['dateFormate'] == '10') {
      dateFormat = moment(time_select).format('YYYY/DD/MM HH:mm:ss');
    } else if (this.settingInfo['dateFormate'] == '11') {
      dateFormat = moment(time_select).format('YYYY/MM/DD hh:mm a');
    } else if (this.settingInfo['dateFormate'] == '12') {
      dateFormat = moment(time_select).format('YYYY/MM/DD HH:mm:ss');
    } else {
      dateFormat = moment(time_select).format('YYYY-MM-DD HH:mm:ss');
    }
    return dateFormat;
  }

  getNewEstimatedTripTimeAfterPOB(
    curLatitude: number = 0,
    curLongitude: number = 0,
    isFirstLocation: boolean = false
  ) {
    console.log('****** curLatitude ******', curLatitude);
    console.log('****** curLongitude ******', curLongitude);
    var duration: number = 0;
    if (
      this.bookingDetails != null &&
      this.bookingDetails['locationJson'] != null
    ) {
      if (this.bookingDetails['locationJson'].length > 1) {
        for (let k = 0; k < this.bookingDetails['locationJson'].length - 1; k++) {
          if (
            this.bookingDetails['locationJson'][k]['isStopArrived'] == 0 ||
            this.bookingDetails['locationJson'][k + 1]['isStopArrived'] == 0
          ) {
            let startLatitudeForLoop: number =
              this.bookingDetails['locationJson'][k].lat ?? 0;
            let startLongitudeForLoop: number =
              this.bookingDetails['locationJson'][k].long ?? 0;

            let endLatitudeForLoop: number =
              this.bookingDetails['locationJson'][k + 1].lat ?? 0;
            let endLongitudeForLoop: number =
              this.bookingDetails['locationJson'][k + 1].long ?? 0;

            var curLatitudeForLoop: number = 0.0;
            var curLongitudeForLoop: number = 0.0;

            if (isFirstLocation == false) {
              curLatitudeForLoop = curLatitude;
              curLongitudeForLoop = curLongitude;
              isFirstLocation = true;
            } else {
              curLatitudeForLoop = startLatitudeForLoop;
              curLongitudeForLoop = startLongitudeForLoop;
            }

            let exp1: number =
              (this.bookingDetails['locationJson'][k + 1].distance ?? 0) * 1000;

            let exp2: number =
              (this.bookingDetails['locationJson'][k + 1].duration ?? 0) * 60.0;

            var speed: number =
              parseFloat(exp1.toString()) / parseFloat(exp2.toString());
            duration =
              duration +
              this.calculateDurationForPOB(
                startLatitudeForLoop,
                startLongitudeForLoop,
                endLatitudeForLoop,
                endLongitudeForLoop,
                curLatitudeForLoop,
                curLongitudeForLoop,
                exp1,
                exp2,
                speed
              );
            console.log('****** duration ******', duration);
            let hoursAndMinutes = this.durationToMinutes(duration);
            this.distanceMinute = hoursAndMinutes;
            this.cdr.detectChanges();
            console.log('****** getNewEstimatedTripTimeAfterPOB  this.distanceMinute ******', this.distanceMinute);
            this.showInfoWindow([this.dropLatLng.lng, this.dropLatLng.lat], this.distanceMinute)
          }
        }
      }
    }
  }

  durationToMinutes(duration: number = 0) {
    var minuitStr: String = '';
    if (duration > 0) {
      var minuit = Math.ceil(duration);
      if (duration > 60) {
        var hour: number = duration / 60;
        minuit = duration - hour * 60;
        if (minuit != 0) {
          minuitStr = hour + ' hr ' + minuit + ' min';
        } else {
          minuitStr = hour + ' hr ';
        }
      } else {
        minuitStr = minuit + ' min';
      }
    }
    return minuitStr;
  }

  calculateDurationForPOB(
    startLatitude: number,
    startLongitude: number,
    endLatitude: number,
    endLongitude: number,
    curLatitude: number,
    curLongitude: number,
    tripDistance: number,
    tripDuration: number,
    speedArg: number
  ): number {
    // Change by  on 28.05.2021 key added in dashboard
    let d: number = this.calcCrow(
      startLatitude,
      startLongitude,
      endLatitude,
      endLongitude
    );
    d = d * 1.9;

    let exp1 = tripDistance;
    var multiplier = exp1 / d;

    let exp2 = tripDuration;
    var speed: number = speedArg;

    d = this.calcCrow(endLatitude, endLongitude, curLatitude, curLongitude);
    d = d * 1.9;

    var passDistance: number = multiplier * d;

    if (passDistance > exp1) {
      passDistance = exp1;
    }

    var duration: number = passDistance / speed;
    duration = duration / 60;
    console.log('****** calculateDurationForPOB duration ******', duration);
    return duration;
  }

  getEstimatedTripTime(
    startLatitude: any,
    startLongitude: any,
    endLatitude: any,
    endLongitude: any
  ) {
    let rRadius = 6371e3;
    let lat1Radians = this.toRadians(startLatitude);
    let lat2Radians = this.toRadians(endLatitude);

    let latRadians = this.toRadians(endLatitude - startLatitude);
    let longRadians = this.toRadians(endLongitude - startLongitude);

    let a =
      Math.sin(latRadians / 2) * Math.sin(latRadians / 2) +
      Math.cos(lat1Radians) *
      Math.cos(lat2Radians) *
      Math.sin(longRadians / 2) *
      Math.sin(longRadians / 2);

    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = rRadius * c;
    d = d * 1.9;

    let speedIs1MetersPerSeconds = 7.0;
    /*distance*/
    let duration = d / speedIs1MetersPerSeconds;
    duration = duration / 60;

    let minuitStr: String = '';
    //        let minuit : Int =  Int(Ceil(duration));
    let minuit = Math.ceil(duration);
    if (duration > 60) {
      let hour = duration / 60;
      minuit = duration - hour * 60;
      if (minuit != 0) minuitStr = hour + ' hr ' + minuit + ' min';
      else minuitStr = hour + ' hr ';
    } else {
      if (duration > 60) {
        let hour = duration / 60;
        minuit = duration - hour * 60;
        if (minuit != 0) minuitStr = hour + ' hr ' + minuit + ' min';
        else minuitStr = hour + ' hr ';
      } else minuitStr = minuit + ' min';
    }
    console.log('****** getEstimatedTripTime minuitStr ******', minuitStr);
    return minuitStr;
  }

  calcCrow(
    lat1: number = 0,
    lon1: number = 0,
    lat2: number = 0,
    lon2: number = 0
  ): number {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    // Converts numeric degrees to radians
    function toRad(Value: number) {
      return (Value * Math.PI) / 180;
    }

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  toRadians(val: number) {
    const PI: number = 3.14159265;
    return (val / 180) * PI;
  }

  angleFromCoordinate(lat1: any, lon1: any, lat2: any, lon2: any) {
    const p1 = { x: lat1, y: lon1 };
    const p2 = { x: lat2, y: lon2 };

    // angle in degrees
    const angleDeg = (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;
    return angleDeg;
  }

  moveCar() {
    this.zone.run(() => {
      console.log('*****>>> move Car <<<******');
      let i = 0;

      const moveCarLogic = () => {
        console.log('*****>>> setInterval move Car <<<******');
        if (this.modeLocations.length <= 0) {
          return;
        } else {
          this.handleMoveCarLogic();
          this.modeLocations.splice(i, 1);
        }
      };

      const myLoop = () => {
        setInterval(moveCarLogic, 1000); /* Changed 1200 */
      };

      myLoop();
    });
  }

  handleMoveCarLogic() {
    if (this.statusFlag == 8 || this.statusFlag == 80) {
      this.handleDriverOnTripLogic();
    } else if (this.statusFlag == 3) {
      this.handleStatus3Logic();
    }
  }

  handleDriverOnTripLogic() {
    if (
      this.bookingDetails['isDestinationLater'] !== 1 ||
      this.bookingDetails['bookingFlag'] !== 1
    ) {
      if (
        this.bookingDetails['isDestinationLater'] == '' &&
        this.bookingDetails['bookingFlag'] !== 1
      ) {
        console.log('****** getNewEstimatedTripTimeAfterPOB  car move ******');
      }
      this.distanceMinute = this.getNewEstimatedTripTimeAfterPOB(
        this.driverCurrentLatLong.lat,
        this.driverCurrentLatLong.lng,
        false
      );
      // this.showInfoWindow([this.dropLatLng.lng, this.dropLatLng.lat], this.distanceMinute);

    }
  }

  handleStatus3Logic() {
    this.distanceMinute = this.getEstimatedTripTime(
      this.pickupLatLng.lat,
      this.pickupLatLng.lng,
      this.driverCurrentLatLong.lat,
      this.driverCurrentLatLong.lng
    );
    this.showInfoWindow([this.pickupLatLng.lng, this.pickupLatLng.lat], this.distanceMinute);
    console.log('****** this.distanceMinute  ******', this.distanceMinute);
  }


  refresh() {
    window.location.reload();
  }

  polylineObject: any[] = [];
  polylinePoints: any[] = [];
  polyLineMarker: any = mapboxgl.Marker;

  content = `{this.distanceMinute}`;
  // drawPolyline(data: any) {
  //   console.log('****** drawPolyline data ******', data);
  //   this.polylinePoints = [];
  //   this.polylineObject = [];

  //   data.forEach((element: any) => {
  //     this.polylineObject.push([element.long, element.lat]);
  //   });

  //   for (let location = 0; location < data.length; location++) {

  //     if (location == 0) {
  //       let el = document.createElement('div');
  //       el.className = 'roundMarker';
  //       // this.addRippleAnimation(el);
  //       this.polyLineMarker = new mapboxgl.Marker(el)
  //         .setLngLat([data[location].long, data[location].lat])
  //         .addTo(this.map);
  //       el.addEventListener('click', () => {
  //         this.showInfoWindow(this.polyLineMarker, this.content);
  //       });
  //     } else {
  //       let el = document.createElement('div');
  //       el.className = 'marker';

  //       this.polyLineMarker = new mapboxgl.Marker(el)
  //         .setLngLat([data[location].long, data[location].lat])
  //         .addTo(this.map);
  //       el.addEventListener('click', () => {
  //         this.showInfoWindow(this.polyLineMarker, this.content);
  //       });
  //     }
  //   }

  //   let coordinates = data
  //     .map((elem: any) => {
  //       return [elem.long, elem.lat];
  //     })
  //     .join(';');

  //   console.log('coordinates map box', coordinates);
  //   console.log('****** this.isCompletedBooking ******', this.isCompletedBooking);
  //   console.log('****** this.bookingDetails flag ******', this.bookingDetails['bookingFlag']);
  //   if (!this.isCompletedBooking && this.bookingDetails['bookingFlag'] != 1) {
  //     this.api.getDirectionFromMapBox(coordinates).subscribe((directionData: any) => {

  //       const durationInSeconds = this.formatSeconds(directionData.routes[0].duration);  // Duration in seconds
  //       const convertedDistances = this.convertDistance(directionData.routes[0].distance);   // Distance in meters
  //       console.log(`Duration: ${durationInSeconds} seconds`);
  //       console.log(`Distance in kilometers: ${convertedDistances.kilometers.toFixed(2)} km`);
  //       console.log(`Distance in miles: ${convertedDistances.miles.toFixed(2)} miles`);

  //       let polylineLatLong = directionData.routes[0].geometry;
  //       // console.log('****** polylineLatLong ******', polylineLatLong);
  //       this.polylineObject = polyline.decode(polylineLatLong);

  //       // console.log('this.polylineObject', this.polylineObject);
  //       this.polylineObject.forEach((element: any) => {
  //         this.polylinePoints.push([element[1], element[0]]);
  //       });
  //       // console.log('****** this.polylinePoints ******', this.polylinePoints);


  //       this.map.addSource('line', {
  //         type: 'geojson',
  //         data: {
  //           type: 'Feature',
  //           properties: {},

  //           geometry: {
  //             type: 'LineString',
  //             coordinates: this.polylinePoints,
  //           },
  //         },
  //       });

  //       // add a line layer without line-dasharray defined to fill the gaps in the dashed line
  //       this.map.addLayer({
  //         type: 'line',
  //         source: 'line',
  //         id: 'line-background',
  //         paint: {
  //           'line-color': 'purple',
  //           'line-width': 6,
  //           'line-opacity': 0.4
  //         },

  //       });

  //       this.map.addLayer({
  //         id: 'line-dashed',
  //         type: 'line',
  //         source: 'line',
  //         layout: {
  //           'line-join': 'round',
  //           'line-cap': 'round',
  //         },
  //         paint: {
  //           'line-color': '#276ef1',
  //           'line-width': 6,
  //           'line-dasharray': [0, 4, 3]
  //         }
  //       });
  //     });
  //   }
  //   if (!this.isCompletedBooking) {
  //     this.adjustMapBounds();
  //   }
  // }

  async drawPolyline(data: any) {
    console.log('****** drawPolyline data ******', data);
    await this.initializePolyline(data);
    await this.createMarkers(data);
    await this.createMapPolyline(data);
  }

  initializePolyline(data: any) {
    this.polylinePoints = [];
    this.polylineObject = [];

    data.forEach((element: any) => {
      this.polylineObject.push([element.long, element.lat]);
    });
  }

  createMarkers(data: any) {
    data.forEach((location: any, index: number) => {
      let el = document.createElement('div');
      el.className = index == 0 ? 'roundMarker' : 'marker';

      this.polyLineMarker = new mapboxgl.Marker(el)
        .setLngLat([location.long, location.lat])
        .addTo(this.map);

      el.addEventListener('click', () => {
        this.showInfoWindow(this.polyLineMarker, this.content);
      });
    });
  }

  createMapPolyline(data: any) {
    let coordinates = data.map((elem: any) => [elem.long, elem.lat]).join(';');

    if (!this.isCompletedBooking && this.bookingDetails['bookingFlag'] != 1) {
      this.api.getDirectionFromMapBox(coordinates).subscribe((directionData: any) => {
        this.processPolylineData(directionData.routes[0].geometry);
      });
    }

    if (!this.isCompletedBooking) {
      this.adjustMapBounds();
    }
  }

  processPolylineData(polylineLatLong: string) {
    this.polylineObject = polyline.decode(polylineLatLong);

    this.polylineObject.forEach((element: any) => {
      this.polylinePoints.push([element[1], element[0]]);
    });

    this.addMapSourceAndLayers();
  }

  addMapSourceAndLayers() {
    this.map.addSource('line', {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: this.polylinePoints,
        },
      },
    });

    this.map.addLayer({
      type: 'line',
      source: 'line',
      id: 'line-background',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': 'black',
        'line-width': 6,
        'line-opacity': 0.4
      },
    });

    // this.map.addLayer({
    //   id: 'line-dashed',
    //   type: 'line',
    //   source: 'line',
    //   layout: {
    //     'line-join': 'round',
    //     'line-cap': 'round',
    //   },
    //   paint: {
    //     // 'line-color': '#276ef1',
    //     'line-color': 'black',
    //     'line-width': 6,
    //     'line-dasharray': [0, 4, 3]
    //   }
    // });
  }




  adjustMapBounds() {
    let bounds: any = new mapboxgl.LngLatBounds();

    let left = window.innerWidth / 3,
      bottom = 0,
      top = window.innerHeight / 4;
    console.log('****** this.statusFlag ******', this.statusFlag);
    // Extend bounds for each location in data
    // console.log('****** this.polylinePoints ******', this.polylinePoints);
    // this.polylinePoints.forEach((element: any) => {
    //   bounds.extend(new mapboxgl.LngLat(element[0], element[1]));
    // });

    // Extend bounds for pickup and drop locations
    // const pickupLocation = data[0];
    // const dropLocation = data[data.length - 1];
    if (this.statusFlag != 8) {
      bounds.extend(new mapboxgl.LngLat(this.pickupLatLng.lng, this.pickupLatLng.lat));
    }
    if (this.bookingDetails['bookingFlag'] != 1) {
      bounds.extend(new mapboxgl.LngLat(this.dropLatLng.lng, this.dropLatLng.lat));
    }

    // Extend bounds for driver location if available
    console.log('****** this.driverCurrentLatLong ******', this.driverCurrentLatLong);
    if (this.driverCurrentLatLong.lat !== 0 && this.driverCurrentLatLong.lng !== 0) {
      bounds.extend(new mapboxgl.LngLat(this.driverCurrentLatLong.lng, this.driverCurrentLatLong.lat));
    }
    // const sidebar: any = document.getElementById('BookingDetailsMainDivHideShow');
    // console.log('****** sidebar ******', sidebar);
    // const sidebarWidth = sidebar.offsetWidth;
    // console.log('****** sidebarWidth ******', sidebarWidth);
    // const newPadding = this.calculateDynamicPadding(sidebarWidth);
    // console.log('****** newPadding ******', newPadding);

    // Set initial padding


    if (window.innerWidth <= 768) {
      left = window.innerWidth / 4;
      bottom = window.innerHeight / 4;
    }
    bottom = window.innerHeight / 4;
    console.log("left", left);
    console.log('****** bottom ******', bottom);
    console.log('****** top ******', top);

    if (window.innerWidth <= 768) {
      this.map.fitBounds(bounds, {
        linear: true,
        padding: 80,
      });
    }
    else {
      this.map.fitBounds(bounds, {
        linear: true,
        padding: { top: top, bottom: bottom, left: left, right: 45 }
      });
    }



    // Fit the map to the adjusted bounds
    // this.map.fitBounds(bounds, {
    //   maxZoom: 16,
    //   linear: true,
    //   padding: 40,
    // });

    this.map.panToBounds(bounds);
    this.map.setZoom(14)
    if (this.map.getZoom() > 17) this.map.setZoom(16);

    // this.map.cameraForBounds(bounds)
    console.log('*****>>> map bound <<<******');
    console.log('****** this.map ******', this.map);
  }


  private infoWindow!: mapboxgl.Popup;
  showInfoWindow(lngLat: mapboxgl.LngLatLike, content: string) {

    const infoWindowContent = `
    <div class="info-window">
      <div class="eta-container">
        <div class="eta-circle"></div>
        <div class="eta-text">${content}</div>
      </div>
    </div>
  `;
    if (!this.infoWindow) {
      // Info window is not created yet, create a new one
      this.infoWindow = new mapboxgl.Popup({ offset: 25, closeButton: false, closeOnClick: false })
        .setLngLat(lngLat)
        .setHTML(infoWindowContent)
        .addTo(this.map);
    } else {
      // Info window is already created, update its content
      this.infoWindow.setHTML(infoWindowContent);
    }
  }






  // Modify animateCar function
  animateCar = () => {
    const path = this.modeLocations;

    // Check if there are more points in the path
    if (this.currentIndex < path.length - 1) {
      const lat = path[this.currentIndex].latitude;
      const long = path[this.currentIndex].longitude;
      const currentPoint: any = [long, lat];

      const nextLat = path[this.currentIndex + 1].latitude;
      const nextLon = path[this.currentIndex + 1].longitude;
      const nextPoint: any = [nextLon, nextLat];

      // Calculate the bearing between the current and next points
      // const bearing = this.calculateBearing(currentPoint, nextPoint);
      const startTime = performance.now();
      const duration = 1100; // 3 seconds

      const animate = (time: any) => {
        const progress = (time - startTime) / duration;
        // if (progress <= 1) {
        const latInterpolated = currentPoint[1] + (nextPoint[1] - currentPoint[1]) * progress;
        const longInterpolated = currentPoint[0] + (nextPoint[0] - currentPoint[0]) * progress;
        const interpolatedPoint: any = [longInterpolated, latInterpolated];

        // Set marker position
        this.carMarker.setLngLat(interpolatedPoint);

        // Calculate and set marker rotation
        const bearing = this.calculateBearing(currentPoint, nextPoint);
        this.carMarker.setRotation(bearing);

        requestAnimationFrame(animate);
        // } else {
        //   console.log('*****>>> Driver reached pickup location <<<******');
        //   setTimeout(() => {
        //     this.currentIndex++;
        //     this.animateCar();
        //   }, 1100);
      }
    };

  }



  // Modify calculateBearing function
  calculateBearing(point1: number[], point2: number[]): number {
    const from = turf.point(point1);
    const to = turf.point(point2);
    const bearing = turf.bearing(from, to);

    return bearing;
  }
  // Function to calculate dynamic padding based on sidebar width
  calculateDynamicPadding(sidebarWidth: any) {
    // Add your logic to determine the appropriate padding based on the sidebar width
    // You may want to consider a percentage of the sidebar width or a fixed value

    // Example: Padding is 20% of the sidebar width
    const percentagePadding = 0.2;
    const dynamicPadding = sidebarWidth * percentagePadding;

    return dynamicPadding;
  }



  themeChange(event: any) {
    console.log('****** event ******', event);
    const bodyColor: any = document.querySelector('body') as HTMLBodyElement;
    if (event.target.checked) {
      this.map.setStyle("mapbox://styles/mapbox/dark-v11")
      bodyColor.style.color = 'white';
      this.infoWindow?.addClassName('blackTextColor')
    }
    else {
      this.map.setStyle("mapbox://styles/mapbox/light-v11")
      bodyColor.style.color = 'black';
    }
  }
  checkDayOrNight() {
    let companyTime = moment1().tz(this.settingInfo['timeZone']).format('HH:mm:ss');
    // companyTime = '01:00:00';
    console.log('****** companyTime ******', companyTime);
    const bodyColor: any = document.querySelector('body') as HTMLBodyElement;

    if (
      this.settingInfo['dayStartTime'] > companyTime &&
      this.settingInfo['dayEndTime'] < companyTime
    ) {
      console.log('*****>>> NIGHT <<<******');
      this.map.setStyle("mapbox://styles/mapbox/dark-v11")
      bodyColor.style.color = 'white';
      this.infoWindow?.addClassName('blackTextColor')
    } else {
      /* day */
      console.log('*****>>> DAY <<<******');
      this.map.setStyle("mapbox://styles/mapbox/light-v11")
      bodyColor.style.color = 'black';
    }
  }


  orderMqttSub(bookingId: any, companyId: any) {
    console.log('****** MQTT bookingId ******', bookingId);
    console.log('****** MQTT companyId ******', companyId);
    try {
      this.orderSubscriptionMqtt = this.MqttServices.topic('restaurant_tracking_' + companyId + '_' + bookingId).subscribe((data: IMqttMessage) => {
        console.log('****** data ******', data);
        let item = JSON.parse(data.payload.toString());
        console.log('****** item ******', item);
        this.eventHandler(item);
      });
    } catch (error) {
      console.log('****** bookingMqtt error ******', error);
    }
  }


  // eventHandler(opts: any) {
  //   console.log(":mqtt::", opts);
  //   if (opts.statusFlag) {
  //     if (opts.statusFlag == this.api.statusFlags['ACCEPT_TRIP']) {
  //       console.log("accept opts", opts);
  //       // this.isBlur = false;
  //       // this.bottomSheet = false;
  //       this.getDriverData(opts.driverDetails);
  //       this.toaster.success('The driver is on the way to the restaurant to get your food.', 'Driver Accepted');
  //       this.refresh();
  //     }
  //     else if (opts.statusFlag == this.api.statusFlags['ARRIVED_AT_RESTAURANT']) {
  //       console.log("driver arrived opts", opts);
  //       this.toaster.success('The driver has arrived at the restaurant', 'Driver At Restaurant');
  //       // this.toaster.default("ARRIVED_AT_RESTAURANT arrived ");
  //     }
  //     else if (opts.statusFlag == this.api.statusFlags['ON_ROUTE_RESTAURANT']) {
  //       this.toaster.success('Driver has picked up your order. Tasty food is on the way.', 'Order Picked Up!');
  //       // this.toaster.default("ON_ROUTE_RESTAURANT arrived ");
  //     }
  //     else if (opts.statusFlag == this.api.statusFlags['ARRIVED_AT_CUSTOMER']) {
  //       console.log("on route opts", opts);
  //       this.toaster.success('Hurray! Driver has arrived at your destination.', 'Order Arrived');
  //       // this.toaster.default("ARRIVED_AT_CUSTOMER arrived ");
  //     }
  //     else if (opts.statusFlag == this.api.statusFlags['COMPLETE_ORDER']) {
  //       this.toaster.success('Order completed successfully. We look forward to start a new chapter with you in the future.', 'Thank you for trusting us!');
  //     }
  //     else if (opts.statusFlag == this.api.statusFlags['CANCEL_ORDER']) {
  //       console.log("-----cancel order");
  //       this.toaster.error('Your order has been cancelled by the restaurant', 'Oops!');
  //       // this.toaster.error("driver arrived ");
  //     }
  //     else if (opts.statusFlag == this.api.statusFlags['TRANSFER_TO_DRIVER']) {
  //       // this.isBlur = true;
  //       // this.isFirstTime = false;
  //       this.toaster.info('We are transferring your order to a Driver that is closer to you.', 'Hang tight!');
  //     }
  //     else if (opts.statusFlag == this.api.statusFlags['EDIT_ORDER']) {
  //       location.reload();
  //       console.log("-----EDIT_ORDER order");
  //       this.toaster.success('Your order has been edited by the restaurant.', 'Order Edited!');
  //     }
  //     else if (opts.statusFlag == this.api.statusFlags['FORCEFULLY_CHANGE_STATUS']) {

  //       console.log("-----FORCEFULLY_CHANGE_STATUS order");
  //       this.toaster.info(`Order status has been changed to "${opts.newStatus}" from "${opts.oldStatus}".`);
  //     }
  //     else if (opts.statusFlag == this.api.statusFlags['FORCEFULLY_DELIVERED']) {
  //       console.log("-----FORCEFULLY_DELIVERED order");
  //       this.toaster.success('Order completed successfully. We look forward to start a new chapter with you in the future.', 'Order Forcefully Delivered');
  //     }
  //     else if (opts.statusFlag == this.api.statusFlags['FAIL_TO_FIND_NEAREST_DRIVER_RESTAURANT']) {
  //       console.log("-----FAIL_TO_FIND_NEAREST_DRIVER_RESTAURANT order");
  //       // this.toastr.error('We are unable to find any driver near by your order', 'Driver Unavailable');
  //     }
  //     else if (opts.statusFlag == this.api.statusFlags['DRIVER_REJECT_BOOKING']) {
  //       console.log("-----DRIVER_REJECT_BOOKING order");
  //       // this.toastr.error('Your order rejected by ', 'Oops!');
  //     }
  //     else if (opts.statusFlag == this.api.statusFlags['UNAVAILABLE_ORDER']) {
  //       console.log("-----UNAVAILABLE_ORDER order");
  //       // this.toastr.error('we are unable to find any driver near by your order', 'Oops!');
  //     }

  //     if (opts['arrivedTime']) {
  //       this.bookingDetails.arrivedTime = opts['arrivedTime'];
  //     }
  //     if (opts['acceptedTime']) {
  //       this.bookingDetails.acceptedTime = opts['acceptedTime'];
  //     }
  //     if (opts['beginTime']) {
  //       this.bookingDetails.beginTime = opts['beginTime'];
  //     }
  //     if (opts['dropTime']) {
  //       this.bookingDetails.dropTime = opts['dropTime'];
  //     }
  //     if (opts['completeTime']) {
  //       this.bookingDetails.completeTime = opts['completeTime'];
  //     }

  //   }
  // }


  eventHandler(opts: any) {
    console.log(":mqtt::", opts);

    const { statusFlag, driverDetails, oldStatus, newStatus, arrivedTime, acceptedTime, beginTime, dropTime, completeTime } = opts;

    switch (statusFlag) {
      case this.api.statusFlags['ACCEPT_TRIP']:
        console.log("accept opts", opts);
        this.getDriverData(driverDetails);
        this.toaster.success('The driver is on the way to the restaurant to get your food.', 'Driver Accepted');
        this.refresh();
        this.showInfoWindow([this.pickupLatLng.lng, this.pickupLatLng.lat], this.distanceMinute);
        break;

      case this.api.statusFlags['ARRIVED_AT_RESTAURANT']:
        console.log("driver arrived opts", opts);
        this.toaster.success('The driver has arrived at the restaurant', 'Driver At Restaurant');
        this.infoWindow.remove();
        break;

      case this.api.statusFlags['ON_ROUTE_RESTAURANT']:
        this.toaster.success('Driver has picked up your order. Tasty food is on the way.', 'Order Picked Up!');
        this.showInfoWindow([this.dropLatLng.lng, this.dropLatLng.lat], this.distanceMinute)
        break;

      case this.api.statusFlags['ARRIVED_AT_CUSTOMER']:
        console.log("on route opts", opts);
        this.toaster.success('Hurray! Driver has arrived at your destination.', 'Order Arrived');
        this.infoWindow.remove();
        break;

      case this.api.statusFlags['COMPLETE_ORDER']:
        this.toaster.success('Order completed successfully. We look forward to start a new chapter with you in the future.', 'Thank you for trusting us!');
        this.infoWindow.remove();
        break;

      case this.api.statusFlags['CANCEL_ORDER']:
        console.log("-----cancel order");
        this.toaster.error('Your order has been cancelled by the restaurant', 'Oops!');
        this.infoWindow.remove();
        break;

      case this.api.statusFlags['TRANSFER_TO_DRIVER']:
        this.toaster.info('We are transferring your order to a Driver that is closer to you.', 'Hang tight!');
        this.infoWindow.remove();
        break;

      case this.api.statusFlags['EDIT_ORDER']:
        location.reload();
        console.log("-----EDIT_ORDER order");
        this.toaster.success('Your order has been edited by the restaurant.', 'Order Edited!');
        this.refresh();
        break;

      case this.api.statusFlags['FORCEFULLY_CHANGE_STATUS']:
        console.log("-----FORCEFULLY_CHANGE_STATUS order");
        this.toaster.info(`Order status has been changed to "${newStatus}" from "${oldStatus}".`);
        this.refresh();
        break;

      case this.api.statusFlags['FORCEFULLY_DELIVERED']:
        console.log("-----FORCEFULLY_DELIVERED order");
        this.toaster.success('Order completed successfully. We look forward to start a new chapter with you in the future.', 'Order Forcefully Delivered');
        this.refresh();
        break;

      case this.api.statusFlags['FAIL_TO_FIND_NEAREST_DRIVER_RESTAURANT']:
        console.log("-----FAIL_TO_FIND_NEAREST_DRIVER_RESTAURANT order");
        break;

      case this.api.statusFlags['DRIVER_REJECT_BOOKING']:
        console.log("-----DRIVER_REJECT_BOOKING order");
        break;

      case this.api.statusFlags['UNAVAILABLE_ORDER']:
        console.log("-----UNAVAILABLE_ORDER order");
        break;
    }

    // Update booking details if corresponding properties exist in opts
    this.bookingDetails.arrivedTime = arrivedTime || this.bookingDetails.arrivedTime;
    this.bookingDetails.acceptedTime = acceptedTime || this.bookingDetails.acceptedTime;
    this.bookingDetails.beginTime = beginTime || this.bookingDetails.beginTime;
    this.bookingDetails.dropTime = dropTime || this.bookingDetails.dropTime;
    this.bookingDetails.completeTime = completeTime || this.bookingDetails.completeTime;
  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscriptionMQTT.unsubscribe();
  }


}
